import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import './index.css';
import './scss/styles.scss';
import reportWebVitals from './reportWebVitals';
import { browser } from './browser';

if (browser.isValid()) {
  const App = React.lazy(() => import('./App'));
  ReactDOM.render(
    <Suspense fallback={''}>
      <App />
    </Suspense>,
    document.getElementById('root')
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
} else {
  document.location = './unsupport.html'
}
